import ChangeQuantity from "./components/ChangeName";
import {
  connectWallet,
  getActiveAccount,
  disconnectWallet,
} from "./utils/wallet";
import { useEffect, useState } from "react";


export default function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
      fetch('https://api.tzkt.io/v1/bigmaps/534331/keys?value=1&limit=5000')
      .then(response => {
        return response.json()})
      .then(data => {
        setData(data);
      })

  }, [])

  const [wallet, setWallet] = useState(null);

  const handleConnectWallet = async () => {
    const { wallet } = await connectWallet();
    setWallet(wallet);
  };
  const handleDisconnectWallet = async () => {
    const { wallet } = await disconnectWallet();
    setWallet(wallet);
  };

  useEffect(() => {
    const func = async () => {
      const account = await getActiveAccount();
      if (account) {
        setWallet(account.address);
      }
    };
    func();
  }, []);

  return (
    <div className="bg-black min-h-screen">
    <div className="bg-black">
      <center><button
          onClick={wallet ? handleDisconnectWallet : handleConnectWallet}
          className="border-2 border-lime bg-black px-6 py-3 rounded-lg text-sm font-semibold text-lime cursor-pointer">
          💳{" "}
          <span style={{fontFamily: "Orbitron", "color": "green", textAlign: "center", lineHeight: "0px"}}>
            {wallet
            ? wallet.slice(0, 4) +
              "..." +
              wallet.slice(wallet.length - 4, wallet.length)
            : "Connect"}</span>
        </button>
        </center>
        </div>

        <br/>
       {wallet ? <ChangeQuantity /> : ""}
         <center>
         <span style={{fontFamily: "Orbitron", "color": "green", textAlign: "center", fontSize:"4vw", lineHeight: "80px"}}>
          {data ? data.length : ""} / 4444 MINTED 
          <br/><br/>
          </span>
          <a href="https://better-call.dev/mainnet/KT1K6KGbDtGTFsnw1eyTk47tnaSickUyd7e4/" target="_blank" rel="noreferrer">
            <span style={{fontFamily: "Orbitron", "color": "green", textAlign: "center", fontSize:"1vw", lineHeight: "0px"}}>
            Crowdsale Contract : KT1K6KGbDtGTFsnw1eyTk47tnaSickUyd7e4
            </span>
          </a>
          <br/>
          <span style={{fontFamily: "Orbitron", "color": "green", textAlign: "center", fontSize:"1vw", lineHeight: "0px"}}>
          Provenance Hash : QmQkWu1CqgUTYz2TB7yna5TSC6pXnBSiaDQcVPtgD3CiAq
          
          </span>
          </center>
        
     </div> 
  );
};

