import { useState } from "react";
import { changeQuantity } from "../utils/wallet";

export default function ChangeQuantity(props) {
  const [quantity, setQuantity] = useState("");
  
  

  return (
    
    <div>
      <div>
      <center>
      <input
        name="name"
        type="number"
        min="1"
        style={{"width": "200px"}}
        placeholder="Number of MGG 3D"
        onChange={(e) => {
          setQuantity(e.target.value);
        }}
        value={quantity}
      />
     <br/>
      <button
        onClick={() => {
          changeQuantity(quantity);
        }}
        className="border-2 mt-2 border-lime bg-black px-6 py-3 rounded-lg text-xl font-semibold  text-lime cursor-pointer"
      >
        
        <span style={{fontFamily: "Orbitron", "color": "green", textAlign: "center", lineHeight: "0px"}}>
          {quantity ? quantity : "0"}x MINT {"-->"} {quantity ? quantity * 5 : "0"} ꜩ
        </span>
      </button>
      </center>
      </div>

<div id="result">

</div>
    </div>
  );
}
